import 'primeicons/primeicons.css';
//import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.css';
import './index.css';
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
// import { Card } from 'primereact/card';
import header_1 from './header_1.png';
import header_2 from './header_2.png';
import Entertainment_Button from './Ent_B_med.png';
import Grocery_Button from './Gro_B_med.png';
import Health_Button from './Heal_B_med.png';
import Lodging_Button from './Lodg_B_med.png';
import Restaurant_Button from './Rest_B_med.png';
import Retail_Button from './Retl_B_med.png';

import dataJSON from './data/data.json';



const App = () => {

    const [stackedData, setData] = useState(dataJSON.group[0].data);
    const [stackedTitle, setTitle] = useState(dataJSON.group[0].title);

    const stackedOptions = {
        tooltips: {
            mode: 'index',
            intersect: false
        },
        responsive: true,
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true
            }]
        }
    };

    let styleEB = {
        width:200,
        height:150,
        background: `url(${Entertainment_Button})`,


    };

    let styleGB = {
        width:200,
        height:150,
        background: `url(${Grocery_Button})`,

       
    };

    let styleRlB = {
        width:200,
        height:150,
        background: `url(${Retail_Button})`,

       
    };

    let styleRsB = {
        width:200,
        height:150,
        background: `url(${Restaurant_Button})`,

        
    };

    let styleLB = {
        width:200,
        height:150,
        background: `url(${Lodging_Button})`,

       
    };

    let styleHB = {
        width:200,
        height:150,
        background: `url(${Health_Button})`,

       
    };

    return (
        <div className="grid-container">
            <div className="grid1">
                    <img src={header_1} alt="Shop My Politics"></img>
                    <img src={header_2} alt="Shop My Politics"></img>
            </div>
            <div className="grid2">
                <Button style={styleRlB}  onClick={() => {setData(dataJSON.group[0].data); setTitle(dataJSON.group[0].title)}}  /> 
                {/* <Button label="Online Stores"  onClick={() => {setData(dataJSON.group[1].data); setTitle(dataJSON.group[1].title)}} className={`p-button-rounded button p-button-danger p-button-online-stores`} icon="pi pi-chevron-right" iconPos="right" />  */}
                <Button style={styleRsB} onClick={() => {setData(dataJSON.group[2].data); setTitle(dataJSON.group[2].title)}}   /> 
                <Button style={styleHB}  onClick={() => {setData(dataJSON.group[3].data); setTitle(dataJSON.group[3].title)}}   /> 
                <Button style={styleEB}  onClick={() => {setData(dataJSON.group[4].data); setTitle(dataJSON.group[4].title)}}   /> 
                {/* <Button label="Technology"  onClick={() => {setData(dataJSON.group[5].data); setTitle(dataJSON.group[5].title)}} className={`p-button-rounded button p-button-danger p-button-technology`} icon="pi pi-chevron-right" iconPos="right" />  */}
                <Button style={styleGB}  onClick={() => {setData(dataJSON.group[6].data); setTitle(dataJSON.group[6].title)}}   /> 
                <Button style={styleLB}  onClick={() => {setData(dataJSON.group[7].data); setTitle(dataJSON.group[7].title)}}   /> 
                {/* <Button label="Repair"  onClick={() => {setData(dataJSON.group[8].data); setTitle(dataJSON.group[8].title)}} className={`p-button-rounded button p-button-repair`} icon="pi pi-chevron-right" iconPos="right" />  */}
                {/* <Button label="Transportation"  onClick={() => {setData(dataJSON.group[9].data); setTitle(dataJSON.group[9].title)}} className={`p-button-rounded button p-button-danger p-button-transportation`} icon="pi pi-chevron-right" iconPos="right" /> */}
                {/* <Card style={{ marginTop: '2em', marginBottom: '2em' }} >
                    Future AD Content
                </Card>
                <Card style={{ marginTop: '2em', marginBottom: '2em' }} >
                    More Future AD Content
                </Card>
                <Card style={{ marginTop: '2em', marginBottom: '2em' }} >
                    Extra Future AD Content
                </Card> */}
            </div>
            <div className="grid3">
                <h2 className="headerCenter">{stackedTitle}</h2>
                <Chart type="horizontalBar" data={stackedData} options={stackedOptions} />
            </div>
        </div>

    )
}

export default App;
